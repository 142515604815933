import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`Avatars are visual representations of people or entities and are often displayed within lists or cards`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/avatar-1.svg",
      "alt": "banner guideline avatar"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Container :`}</strong>{` a container that can be used for place image icon or initals to represent some entity`}</li>
      <li parentName="ol"><strong parentName="li">{`Badge (Optional) :`}</strong>{` an optional element that can show current status or information to support the avatar`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`The typical usage for an avatar is to represent the current user in the masthead. However, based on your product’s use cases and needs, there is room for customization.`}</p>
    <p><strong parentName="p">{`Common places avatar appear:`}</strong>{` Cards, accordions, data lists, tables, etc`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li> Avatar is a visual used to represent an entity or an object.</li>
      <li> Use an avatar to help people efficiently identify another person or company in the platform visually.</li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li> Don’t use single avatar represent an entity or group. </li>
      <li> Avoid photos that don’t fill the avatar mask. </li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`Avatars make identifying a person, team or organization more efficient, while still allowing users to express themselves.
An avatar is a visual used to represent a user. It may contain an image or a placeholder graphic. Typical usage is to represent the current user in the masthead.
Keyboard users shouldn’t be able to interact with the avatar on its own.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do : With Initial" caption="Use the default alternative if no image source is available. This will be the first character of the provided name" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/avatar-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't : Without image or initial" caption="Don’t use alternative graphics or icons to provide the profile. Used avatar with image for better option" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/avatar-3.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When creating avatar :`}</p>
    <ul>
      <li parentName="ul">{`A text label identifies the subject of the avatar.`}</li>
      <li parentName="ul">{`Prioritize images and icons over text.`}</li>
      <li parentName="ul">{`Use inline avatars to mention people in a sentence.`}</li>
      <li parentName="ul">{`You can use badge to indicate one status.`}</li>
      <li parentName="ul">{`Avoid using landscape or portrait images as avatars. Let users crop their images before or after uploading if possible.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do : With label" caption="Use text label although there is already an image in the avatar" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/avatar-4.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't : Without label" caption="Leave without text label to give information about a person" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/avatar-5.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      